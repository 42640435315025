<template>
  <div>
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("administrators.addN") }}</h1>
        <h5 class="text-primary">
          {{ $t('code.final') }}
        </h5>
      </b-col>
      <b-col class="text-right pr-3 pt-2">
        <b-link
          :to="{
            name: 'administrators-list'
          }"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="danger"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </b-link>
      </b-col>
    </b-row>

    <!-- BODY -->
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-card>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col md="4">
              <!-- Username -->
              <b-form-group :label="$t('administrators.user') + ' *'">
                <validation-provider
                  v-slot="validationContext"
                  name="username"
                  rules="required|alpha-dash"
                >
                  <b-form-input
                    id="username"
                    v-model="userData.username"
                    autocomplete="new-password"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :placeholder="$t('userName')"
                  />
                  <small class="text-danger">{{ validationInput(validationContext) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <!-- First Name -->

              <validation-provider
                v-slot="validationContext"
                name="first-name"
                rules=""
              >
                <b-form-group :label="$t('administrators.name')">
                  <b-form-input
                    id="first-name"
                    v-model="userData.firstName"
                    autocomplete="new-password"
                    autofocus
                    :state="validationContext.errors.length > 0 ? false : null"
                    trim
                    :placeholder="$t('firstName')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <!-- Last Name -->
              <validation-provider
                v-slot="validationContext"
                name="last-name"
                rules=""
              >
                <b-form-group :label="$t('administrators.lname')">
                  <b-form-input
                    id="last-name"
                    v-model="userData.lastName"
                    autocomplete="new-password"
                    autofocus
                    :state="validationContext.errors.length > 0 ? false : null"
                    trim
                    :placeholder="$t('lastName')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Email -->
              <validation-provider
                v-slot="validationContext"
                name="Email"
                rules="email|required"
              >
                <b-form-group :label="$t('labelEmail') + ' *'">
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    autocomplete="new-password"
                    :state="validationContext.errors.length > 0 ? false : null"
                    trim
                    :placeholder="$t('labelEmail')"
                  />
                  <b-form-invalid-feedback>
                    {{
                      checkMail(validationContext.failedRules) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('pin')">
                <b-form-input
                  id="mc-pin-name"
                  v-model="userData.pin"
                  autocomplete="new-password"
                  placeholder="Pin de usuario"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('finalUsers.usgen')">
                <b-form-select
                  v-model="userData.gender"
                  :options="[
                    { value: 'M', text: $t('Hombre') },
                    { value: 'F', text: $t('Mujer') },
                  ]"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <!-- Password -->
              <validation-provider
                v-slot="validationContext"
                name="password"
                vid="password"
                :rules="{
                  required: true,
                  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/
                }"
              >
                <b-form-group :label="$t('administrators.pass') + ' *'">
                  <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="userData.password"
                      autocomplete="new-password"
                      :type="passwordFieldType"
                      :placeholder="$t('administrators.pass')"
                      :state="validationContext.errors.length > 0 ? false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    checkPassword(validationContext)
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Password -->

              <validation-provider
                v-slot="validationContext"
                name="c-password"
                rules="required|confirmed:password"
              >
                <b-form-group :label="$t('administrators.repass') + ' *'">
                  <b-input-group>
                    <b-form-input
                      id="c-password"
                      v-model="userData.cpassword"
                      autocomplete="new-password"
                      :type="passwordFieldType"
                      :placeholder="$t('administrators.repass')"
                      :state="validationContext.errors.length > 0 ? false : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">
                    {{
                      checkPassword(validationContext)
                    }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="subgroups"
              md="4"
            >
              <b-form-group
                v-if="hasSubgroups && checkPermissions('users.add_subgroup')"
                :label="$t('Subgroup')"
              >
                <v-select
                  id="subgroup"
                  v-model="userData.subgroup"
                  :options="subgroups"
                  label="name"
                  :placeholder="$t('SinGrupoUsuario')"
                  aria-autocomplete="new-password"
                  autocomplete="new-password"
                  :reduce="option => option.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-left">
              <!-- Form Actions -->
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  type="submit"
                >
                  {{ $t("administrators.addbu") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BCol,
  BFormSelect,
  BCard,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from '@axios'
import {
  getSubgroups, checkPermissions, isUserInGroup, showToast,
} from '@/store/functions'

import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BRow,
    BCol,
    BCard,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      blankUserData: {
        firstName: '',
        lastName: '',
        gender: 'M',
        pin: null,
        username: '',
        email: '',
        password: '',
        cpassword: '',
        subgroup: null,
      },
      userData: {},
      required,
      email,
      min,
      confirmed,
      selectedGroups: null,
      groups: [],
      forbid: false,
      isShop: false,
      hasSubgroups: false,
      subgroups: null,
      checkPermissions,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    const user = getUserData()
    const loguinUserAdmin = isUserInGroup(user, constants.administrador)

    try {
      if (!loguinUserAdmin) {
        this.forbid = true
      }
      this.isShop = user.profile.client.isShop
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

    this.hasSubgroups = user.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
    }
    this.userData = JSON.parse(JSON.stringify(this.blankUserData))
    this.userData.subgroup = user.profile.subgroup?.id
  },
  methods: {
    onSubmit() {
      const userInfo = this.userData

      const json = getUserData()
      const client = json.profile.client.id
      const clientName = json.profile.client.name
      axios
        .post('', {
          query: `
            mutation($subgroup: ID) {
              createVuser(input: {
                username: "${clientName}_${userInfo.username}",
                password: "${userInfo.password}",
                firstName: "${userInfo.firstName}",
                lastName: "${userInfo.lastName}",
                email: "${userInfo.email}",
                ${userInfo.pin ? `pin:${userInfo.pin},` : ''}
                gender:${userInfo.gender},
                client: "${client}",
                subgroup:$subgroup}) {
                vUser {
                  id
                  username
                  subgroup{
                    id
                    name
                  }
                }
              }
            }
            `,
          variables: {
            subgroup: userInfo.subgroup,
          },
        })
        .then(response => {
          const { data, errors } = response.data
          if (data.createVuser !== null) {
            showToast(this.$t('success'), 1, this)
            window.history.back()
          } else {
            errors.forEach(e => {
              showToast(e.message, 2, this)
            })
          }
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
        })
    },
    validationInput(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      if (keyContent.includes('alpha-dash')) {
        return this.$t('userNameNotValid')
      }
      return ''
    },
    checkMail(rule) {
      if (rule.email) {
        return this.$t('emailValidField')
      }
      if (rule.required) {
        return this.$t('emailField')
      }
      return ''
    },

    checkPassword(validationContext) {
      const failedRules = validationContext.failedRules || {}

      if (failedRules.required) {
        return this.$t('password') // "La contraseña es obligatoria"
      }
      if (failedRules.min) {
        return this.$t('longPassword') // "Debe tener al menos 8 caracteres"
      }
      if (failedRules.regex) {
        return this.$t('contentPassword') // "Debe incluir mayúscula, minúscula, número y carácter especial"
      }
      if (failedRules.confirmed) {
        return this.$t('confirmPassword') // "Las contraseñas no coinciden"
      }

      return ''
    },
    selectOptions(data) {
      const mappedData = data.map(item => (
        {
          value: item.name,
          label: item.description,
        }
      ))
      return mappedData
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
